import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import PlayerList from '../components/PlayerList';
import SEO from '../components/SEO';

const ButtonStyle = styled.div`
  margin: 6rem auto;
  text-align: center;
  font-family: var(--hed-family);
  a {
    margin: 0 auto;
    width: auto;
  }
`;

const NewsSection = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  iframe {
    display: block;
    margin: 5px auto;
    border: 0;
    width: 100%;
    height: 60vh;
    min-height: 450px;
    max-width: 750px;
  }
`;

export default function PlayersPage({ data }) {
  const players = data.players.nodes;
  players.sort((a, b) => (a.debut > b.debut ? 1 : -1));
  // console.log(players);

  return (
    <>
      <SEO title="Current MLB Players from Japan" />
      <p>There are {players.length} Japanese players active in MLB this year</p>
      <PlayerList players={players} />
      <ButtonStyle>
        <Link to="/players/" className="button">
          View All JapaneseBallPlayers
        </Link>
      </ButtonStyle>
      <NewsSection>
        <iframe
          title="JBP News feed"
          allowFullScreen
          src="https://raindrop.io/daigofuji/jbp-news-33123393/embed/sort=-created"
          // `https://raindrop.io/daigofuji/jbp-news-33123393/embed/sort=-created&perpage=30&page=0&search=%23${aoyagi}`
        >
          iframe
        </iframe>
      </NewsSection>
    </>
  );
}

export const query = graphql`
  query IndexQuery {
    players: allSanityPlayer(filter: { status: { eq: "Active" } }) {
      nodes {
        name
        debut
        dob
        bats
        throws
        position
        currentteam {
          id
          name
          color
          image {
            asset {
              fixed {
                src
              }
            }
          }
          slug {
            current
          }
        }
        currentminor
        href_bbref
        href_mlb
        href_nikkan
        href_npb
        icon
        id
        namejp
        nameyomi
        status
        size
        slug {
          current
        }
      }
    }
  }
`;
